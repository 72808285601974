import { mapKeys } from 'lodash'

export function renameKeys<V>(object: Record<string, V>, mapping: Record<string, string>): Record<string, V> {
  return mapKeys(object, (_, key) => ((key in mapping) ? mapping[key] : key))
}

export function compactObject<T extends Record<string, unknown>>(object: T): T {
  return Object.fromEntries(Object.entries(object).filter(([, value]) => value != null)) as T
}

export function isEmptyObject(value: any): boolean {
  if (value === null || value === undefined || value === '') {
    return true // Handle null, undefined, and empty strings
  }
  if (Array.isArray(value)) {
    // Check if all elements in the array are empty
    return value.every(isEmptyObject)
  }
  if (typeof value === 'object') {
    // Check if all properties in the object are empty
    return Object.keys(value).length === 0 || Object.values(value).every(isEmptyObject)
  }
  return false // For all other types, consider them non-empty
}

export function ObjectTypedKeys<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>
}
